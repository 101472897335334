import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdalLoginService } from 'src/app/services/adal-login.service';

@Component({
  selector: 'app-choose-environment',
  templateUrl: './choose-environment.component.html',
  styleUrls: ['./choose-environment.component.scss']
})
export class ChooseEnvironmentComponent implements OnInit {
  public environmentList:Array<string>;
  public env:string;
  constructor(private adalLoginService:AdalLoginService) {
    this.environmentList = ["QA","Pre dev","Dev","Pre prod","Prod"];
   }

  ngOnInit(): void {}

  setCheckedValue(event){
    this.env = event.target.value;
  }

  loginToEnv() {
    if(this.env){
      this.adalLoginService.init(this.env);
      localStorage.setItem("env",this.env)
    }
  }
}
