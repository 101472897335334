import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHandlerService } from './http-handler.service';

@Injectable({
  providedIn: 'root'
})
export class UploadDataService {
  private uploadDataSource = new Subject<any>();
  public uploadDataSource$ = this.uploadDataSource.asObservable();

  constructor(private httpHandler:HttpHandlerService) { }

  async uploadData(files,token){
    (await this.uploadDataRequest(files,token)).subscribe(data=>{
      this.uploadDataSource.next(data);
    })
  }
  uploadDataRequest(data,token){
    let baseUri = environment[localStorage.getItem('env')].baseUri;
    return this.httpHandler.post(baseUri+'update-data/upload', data,token)
  }
}
