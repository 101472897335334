import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChooseEnvironmentComponent } from './component/choose-environment/choose-environment.component';
import { UploadDataComponent } from './component/upload-data/upload-data.component';

const routes: Routes = [
  { path: '', component: ChooseEnvironmentComponent },
  { path:'choose-environment', component: ChooseEnvironmentComponent},
  { path: 'upload-data', component:UploadDataComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
