<app-header></app-header>
<div class=" card">
  <div class=" card-header">
    <h3 class=" mb-0">Choose an environment to upload the files:</h3>
  </div>
  <div class=" card-body">
    <form>
      <div class=" row">
        <div class=" col-md-6">
          <div class=" custom-control custom-radio mb-3" *ngFor="let environment of environmentList; let i = index">
            <input class="custom-control-input" id=customRadio{{i}} name="custom-radio-1" type="radio"
              (change)="setCheckedValue($event)" value="{{environment}}" />
            <label class="custom-control-label" for=customRadio{{i}}>{{environment}}</label>
          </div>
        </div>
      </div>
    </form>
    <div class="wrap-send-btn">
      <button class="btn btn-default" (click)="loginToEnv()">Next</button>
    </div>
  </div>