import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AdalLoginService } from './adal-login.service';

@Injectable({
  providedIn: 'root'
})
export class HttpHandlerService {

  constructor(private http: HttpClient,private adalLoginService:AdalLoginService) { }
  
  private async buildHeaders(token?) {
    var headers = new HttpHeaders({
      Authorization: token ? "Bearer "+ token : "Bearer "+ await this.adalLoginService.getTokenValue()
    });
    return headers;
  }
  async get<T>(fullUrl, data?): Promise<Observable<T | void | {}>> {
    var options = { headers:  await this.buildHeaders()};
    if (typeof data !== 'undefined') {
      options['params'] = data;
    }
    return this.http.get<T>(fullUrl, options).pipe(catchError(async (err) => this.handleError(err)))
  }
  async post<T>(fullUrl, data,token): Promise<Observable<T | void | {}>> {
    return this.http.post<T>(fullUrl, data, {headers:await this.buildHeaders(token)}).pipe(catchError(async (err) => this.handleError(err)));
  }
  async put<T>(fullUrl, data): Promise<Observable<T | void | {}>> {
    return this.http.put<T>(fullUrl, data, {headers:await this.buildHeaders()}).pipe(catchError(async (err) => this.handleError(err)))
  }
  async delete<T>(fullUrl): Promise<Observable<T | void | {}>> {
    return this.http.delete<T>(fullUrl, {headers: await this.buildHeaders()}).pipe(catchError(async (err) => this.handleError(err)))
  }

  handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 403: {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.Status
        })
        break;
      }
      default: {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something is wrong, please contact the system administrator'
        })
      }
    }
  }
}
