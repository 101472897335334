<app-header></app-header>
<div class=" card">
    <div class=" card-header">
      <h3 class=" mb-0">Upload Data to {{environment}}</h3>
    </div>

    <div class=" card-body">
      <div class=" dropzone dropzone-multiple" id="dropzone-multiple">
        <div class=" fallback">
          <div class=" custom-file">
            <input class=" custom-file-input" id="customFileUploadMultiple" multiple="multiple" type="file" />

            <label class=" custom-file-label" for="customFileUploadMultiple">
              Choose file
            </label>
            <!-- <div class="dz-message" data-dz-message><span>Drop files here to upload</span></div> -->
          </div>
        </div>

        <ul class=" dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
          <li class=" list-group-item px-0">
            <div class=" row align-items-center">
              <div class=" col-auto">
                <div class=" avatar">
                  <!-- <img alt="..." class=" avatar-img rounded" data-dz-thumbnail="" src="..." /> -->
                </div>
              </div>

              <div class=" col ml--3">
                <h4 class=" mb-1" data-dz-name="">...</h4>

                <p class=" small text-muted mt-0" data-dz-size="">...</p>
              </div>

              <div class=" col-auto">
                <button data-dz-remove="true" class="btn btn-danger btn-sm">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="wrap-send-btn">
        <button class="btn btn-default" (click)="uploadData()" >Upload</button>
      </div>
    </div>
  </div>