export const environment = {
  production: false,
  "QA":{
    baseUri:'https://api.rtba1-qa.apps4.icldig.icl-group.com/',
    adalConfig: {
      tenant: '802762d2-02c4-4677-98ba-54060a234204',
      clientId: 'e7212e05-8715-49d9-adab-84a0684cc9c2',
      expireOffsetSeconds: 320, //15 minutes (60-15 = 45 minutes auth)
      popUp: false,
      cacheLocation: 'localStorage',
      resource: 'https://graph.microsoft.com',
      redirectUri:'https://rtba1-automation-dataset.apps4.icldig.icl-group.com/upload-data/'
    }
  },
  "Pre dev":
  {
    baseUri:'https://api.rtba1-dev.apps4.icldig.icl-group.com/pre/',
    adalConfig: {
      tenant: '802762d2-02c4-4677-98ba-54060a234204',
      clientId: 'c5c411db-4e52-419a-a583-85a4f67bee2b',
      expireOffsetSeconds: 320, //15 minutes (60-15 = 45 minutes auth)
      popUp: false,
      cacheLocation: 'localStorage',
      resource: 'https://graph.microsoft.com',
      redirectUri:'https://rtba1-automation-dataset.apps4.icldig.icl-group.com/upload-data/'
    }
  },
  "Dev":
  {
    baseUri:'https://api.rtba1-dev.apps4.icldig.icl-group.com/',
    adalConfig: {
      tenant: '802762d2-02c4-4677-98ba-54060a234204',
      clientId: '4331a39e-68e4-4ab8-b58b-66e0e9ad4168',
      expireOffsetSeconds: 320, //15 minutes (60-15 = 45 minutes auth)
      popUp: false,
      cacheLocation: 'localStorage',
      resource: 'https://graph.microsoft.com',
      redirectUri:'https://rtba1-automation-dataset.apps4.icldig.icl-group.com/upload-data/'
    }
  },
  "Pre prod":
  {
    baseUri:'https://api.rtba1.apps4.icldig.icl-group.com/pre/',
    adalConfig: {
      tenant: '802762d2-02c4-4677-98ba-54060a234204',
      clientId: 'c04e8a79-97d0-4a6a-9e7d-4d4e9731159c',
      expireOffsetSeconds: 320, //15 minutes (60-15 = 45 minutes auth)
      popUp: false,
      cacheLocation: 'localStorage',
      resource: 'https://graph.microsoft.com',
      redirectUri:'https://rtba1-automation-dataset.apps4.icldig.icl-group.com/upload-data/'
    }
  },
  "Prod":
  {
    baseUri:'https://api.rtba1.apps4.icldig.icl-group.com/',
    adalConfig: {
      tenant: '802762d2-02c4-4677-98ba-54060a234204',
      clientId: '1876aff9-7d03-40fe-9de0-cac7db2907b6',
      expireOffsetSeconds: 320, //15 minutes (60-15 = 45 minutes auth)
      popUp: false,
      cacheLocation: 'localStorage',
      resource: 'https://graph.microsoft.com',
      redirectUri:'https://rtba1-automation-dataset.apps4.icldig.icl-group.com/upload-data/'
    }
  }  
};