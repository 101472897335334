import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import Dropzone from "dropzone";
import { environment } from 'src/environments/environment';
import { AdalLoginService } from '../../services/adal-login.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpHandlerService } from 'src/app/services/http-handler.service';
import { UploadDataService } from 'src/app/services/upload-data.service';

Dropzone.autoDiscover = false;
@Component({
  selector: 'app-upload-data',
  templateUrl: './upload-data.component.html',
  styleUrls: ['./upload-data.component.scss']
})
export class UploadDataComponent implements OnInit {
  currentMultipleFile: FormData;
  dropZone: Dropzone;
  environment: string;
  constructor(private uploadDataService: UploadDataService, private adalLoginService: AdalLoginService, private router: Router) {
    this.environment = localStorage.getItem('env');
    const queryString = window.location.hash;
    if (!queryString.includes("id_token")) {
      this.router.navigateByUrl('choose-environment');
    }
  }

  ngOnInit(): void {
    this.initDropZone();
  }

  initDropZone() {
    // multiple dropzone file - accepts any type of file
    var t = this;
    this.dropZone = new Dropzone(document.getElementById("dropzone-multiple"), {
      url: "https://",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName(
        "dz-preview-multiple"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-multiple")[0]
        .innerHTML,
      maxFilesize: 5,
      acceptedFiles: ".json",
    });
    document.getElementsByClassName("dz-preview-multiple")[0].innerHTML = "";
    this.dropZone.on("removedfile", (file) => {
      t.removeFileFunc(file)
    })
    this.dropZone.on("addedfile", (file) => {
      t.addedFileFunc(file)
    });
  }

  addedFileFunc(file: File) {
    if (!this.currentMultipleFile) {
      this.currentMultipleFile = new FormData();
    }
    this.currentMultipleFile.append('files', file);
  }

  removeFileFunc(file: File) {
    const files = this.currentMultipleFile.getAll('files')
    this.currentMultipleFile.delete('files')

    files.filter((singleFile: File) => singleFile.name !== file.name)
      .forEach(name => name ? this.currentMultipleFile.append('files', name) : '')
  }

  async uploadData() {
    if (this.currentMultipleFile && this.currentMultipleFile.getAll('files').length > 0) {
      Swal.fire({
        title: 'Are you sure you want to upload this data?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.showLoading();
          const queryString = window.location.hash;
          let idToken = queryString.slice(queryString.indexOf("=") + 1, queryString.indexOf("&"))
          await this.uploadDataService.uploadData(this.currentMultipleFile, idToken);
          this.uploadDataService.uploadDataSource$.subscribe(data => {
            if (data) {
              Swal.hideLoading();
              this.dropZone.files.forEach((file: any) => { file.status = "queued" });
              this.dropZone.removeAllFiles();
              this.showAlertSuccess();
              this.router.navigateByUrl('choose-environment')
            }
            else {
              this.showAlertError();
            }
          })
        }
      })
    }
  }

  showLoading() {
    Swal.fire({
      title: 'Please Wait...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
  }

  showAlertSuccess() {
    Swal.fire({
      icon: 'success',
      title: 'All files upload',
      showConfirmButton: false,
      timer: 15000
    });
  }

  showAlertError() {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Something is wrong, please contact the system administrator'
    })
  }
}
