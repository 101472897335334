import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdalLoginService {

  private token: string = null;

  constructor(private adalService: AdalService) {
    
  }

  init(selectedEnvironment:string){
    this.adalService.init(environment[selectedEnvironment].adalConfig);
    this.adalService.handleWindowCallback();
    this.login()
  }
  login() {
    this.adalService.login();
  }

  logOut() {
    this.adalService.logOut();
  }

  isLoggedIn() {
    return this.adalService.userInfo.authenticated;
  }

  async getToken() {
    if (this.adalService.userInfo.authenticated) {
      this.token = await this.adalService.acquireToken(this.adalService.config.loginResource).toPromise();
      this.adalService.userInfo.token = this.token;
    }
  }
  async getTokenValue() {
    if (!this.token) {
      await this.getToken();
    }
    return this.token
  }
}
